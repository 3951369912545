// Targets handheld (480px width) and below
// --------------------------------------------------
@media (max-width: $screen-xs) {
  #site-header {
    .logo-primary {
      height: 45px;
    }

    .container > .container {
      padding: 0 0 0 8px;
    }

    .header-brand-container {
      padding-bottom: 0;
    }

    .header-loyalty-container {
      padding-top: 0;

      .loyalty-summary {
        margin-top: 0;

        .profile-name {
          display: block;
        }
      }
    }
  }
}

// Targets tablet and below
// --------------------------------------------------
@media (max-width: 768px) {
  #site-header .container {
    padding: 0 8px;
  }
  #site-header .container > .container {
    padding: 0;
  }
}